import type { ValidationResult } from "@component-utils/validations"

export type SharedProps = {
  /**
   * Element id
   */
  id?: string
  /**
   * Element name
   */
  name?: string
  /**
   * Label above the component
   */
  label?: string
  /**
   * Tooltip for label above the component
   */
  labelTooltip?: string
  /**
   * Hint under the component
   */
  description?: string
  /**
   * Element is not interactable and is styled appropriate
   */
  disabled?: boolean
  /**
   * Element is not editable
   */
  readonly?: boolean
}

export const SHARED_PROPS_DEFAULTS: SharedProps = {
  id: undefined,
  name: undefined,
  label: undefined,
  labelTooltip: undefined,
  description: undefined,
  disabled: undefined,
  readonly: undefined
}

export type ValidationProps<T> = {
  /**
   * Validation method
   * @param value Value to be validated
   * @returns Result
   */
  validator?: (value: undefined | null | T) => ValidationResult
  /**
   * When to run validations
   */
  validationTrigger?: 'input' | 'immediate'
}

export const VALIDATION_PROPS_DEFAULTS: ValidationProps<unknown> = {
  validator: undefined,
  validationTrigger: undefined
}